import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Privacy Policy">
    <section className="section-privacy-policy">

        <b><u>BellMe Privacy Statement</u></b><br/><br/>

        BellMe is committed to maintaining the privacy of its users of the
        BellMe website (the "Site") and its customers.  This Privacy Statement
        explains what kind of information we gather at our Site, and how that information
        is used.  Please contact us at <a href="mailto: info@bellme.xyz">info@bellme.xyz</a> if you have
        any questions about this Privacy Policy.<br/><br/>

        I.&nbsp;&nbsp;&nbsp;How We Use Your Information<br/><br/>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You can visit the public parts of the Site
        without telling us who you are or revealing any information about yourself.  While
        your IP address (the Internet address of your computer) may be logged to track your
        session, we do not link IP addresses to anything personally identifiable.  This means
        that even if your session is tracked, you will remain anonymous.  We track IP addresses
        to better understand which parts of the Site users are visiting and how long they
        spend there, to diagnose problems with our servers and for system maintenance.<br/><br/>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In order to use the non-public parts of the Site and to
        order ground transportation or other services ("Services"), you first must complete a
        registration form.  During registration, you are required to give us information about
        yourself, including your name, e-mail address, home and/or work address and telephone
        numbers, company name and travel plans.  Throughout this Privacy Statement, we refer
        to all information you provide to us which could be used to identify you as an
        individual - including your name, e-mail address, addresses, telephone numbers, employer
        and travel plans- as your "Personal Information." <br/><br/>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We will use your Personal Information if we need to contact
        you about the Services.  We also may disclose your personal information to third party
        service providers to enable them to provide the Services to you.  We also may disclose
        Personal Information if required to do so by law, court order, as requested by other
        government or law enforcement authority, or in the good faith belief that disclosure is
        otherwise necessary or advisable including, without limitation, to protect our rights or
        properties, or when we have reason to believe that disclosing the information is necessary
        to identify, contact or bring legal action against someone who may be causing interference
        with our rights or properties, whether intentionally or otherwise, or when anyone else
        could be harmed by such activities.<br/><br/>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We may share aggregated demographic information about our
        users with our partners and advertisers.  This is not linked to any Personal Information.
        We will not sell or provide your Personal Information to anyone without your consent.<br/><br/>

        II.&nbsp;&nbsp;&nbsp;Cookies<br/><br/>

        &nbsp;&nbsp;&nbsp;&nbsp;"Cookies" are small pieces of information that are stored on
        your computer's hard drive.  We use cookies so that you can customize the Site to
        suit your tastes, and to deliver content specific to your interests.  Most Web browsers
        automatically accept cookies, but if you prefer, you can edit your browser options to block
        them in the future.  We do not use cookies to correlate your activities with your Personal
        Information, and we do not provide any information regarding your cookies to any third
        party.<br/><br/>

        III.&nbsp;&nbsp;&nbsp;Links<br/><br/>

        &nbsp;&nbsp;&nbsp;&nbsp;The Site contains links to other sites.  Please be aware
        that BellMe is not aware of or responsible for the privacy practices of such other
        sites.  We encourage our users to be aware when they leave our site and to read the privacy
        statements of each and every web site that may collect personally identifiable information.
        This Privacy Statement applies solely to Personal Information collected by our Site.<br/><br/>

        IV.&nbsp;&nbsp;&nbsp;Correcting/Updating Personal Information<br/><br/>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you want to correct or update your Personal Information,
        you may contact us at <a href="mailto: info@bellme.xyz">info@bellme.xyz.</a> <br/><br/>

        V.&nbsp;&nbsp;&nbsp;Notification of Changes<br/><br/>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If we decide to change our Privacy Statement, we will post
        those changes on our Site so our users are always aware of what information we collect,
        how we use it, and under what circumstances, if any, we disclose it.  If at any point we
        decide to use Personal Information in a manner different from that stated at the time it
        was collected, we will notify you at the e-mail address you provide during registration.
        You then will have a choice as to whether or not we use your Personal Information in
        this different manner. <br/><br/>


    </section>
  </Layout>
);

export default Page;
